module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-bootpack/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-cognito-appsync/gatsby-browser.js'),
      options: {"plugins":[],"userPoolId":"eu-west-1_Xf50pxxO4","userPoolWebClientId":"49rkpkfnmsgt771et3bt4li67l","region":"eu-west-1","redirectSignIn":"https://trainer.weareheadlight.com/","redirectSignOut":"https://www.weareheadlight.com/","cognito_domain":"account.weareheadlight.com","graphql_endpoint":"https://graphql.weareheadlight.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coach","short_name":"Coach","start_url":"/","background_color":"#1029B9","theme_color":"#1029B9","display":"minimal-ui","icon":"src/images/Headlight-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"233d30ce7cda128f6f41c5e5886ae1dd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
