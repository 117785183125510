import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'gatsby-theme-cognito-appsync';

const clientID = process.env.GATSBY_AWS_COGNITO_APP_CLIENT_ID;
const redirectURI = process.env.GATSBY_URL_REDIRECT_SIGNIN;
const cognitoURL = `https://${process.env.GATSBY_AWS_COGNITO_DOMAIN}/login?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}`;

function Auth({ children }) {
  const isSignedIn = useAuth();
  function authCheck() {
    if (isSignedIn === false) {
      window.location.replace(cognitoURL);
    }
  }
  useEffect(authCheck, [isSignedIn]);
  if (isSignedIn !== true) return null;
  return children;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
