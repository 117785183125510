import React from 'react';
import Auth from './auth';

function wrapPageElement({ element }) {
  return (
    <Auth>
      {element}
    </Auth>
  );
}

export default wrapPageElement;
