import './src/style/icons';
import wrapPageElement from './src/components/wrapPageElement';
// import './src/lib/sentry';
// import './src/lib/bootstrap';
// import './src/style/custom.scss';

function onServiceWorkerUpdateReady() {
  window.location.reload();
}

export {
  wrapPageElement,
  onServiceWorkerUpdateReady,
};
